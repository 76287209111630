import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import Link from 'next/link';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { Styles } from './index';

import css from './index.module.scss';

export type PaginationItemProps = {
  customClass?: string;
  content: ReactNode;
  link?: {
    url: string;
    target?: '_blank' | '_self';
  };
  status?: 'current' | 'disabled';
  onClick?: () => void;
  shallow?: boolean;
};

export function PaginationItem({
  customClass,
  content,
  link,
  status,
  onClick,
  shallow,
}: PaginationItemProps) {
  return status || !link?.url ? (
    <PepitaButton
      customClass={clsx(
        [css[Styles.item]],
        customClass,
        status === 'current' && css[`${Styles.item}--current`]
      )}
      disabled={status === 'disabled'}
      variant="ghost"
      as="div"
    >
      {content}
    </PepitaButton>
  ) : (
    <Link
      href={link.url}
      passHref
      shallow={shallow}
      className={clsx(
        [css[Styles.item]],
        'nd-button',
        'nd-button--ghost',
        customClass
      )}
      target={link.target && link.target}
      onClick={() => onClick && onClick()}
      onKeyDown={(event) => {
        if (onClick && (event.key === 'Enter' || event.keyCode === 13)) {
          onClick();
        }
      }}
      role="link"
      tabIndex={0}
    >
      {content}
    </Link>
  );
}
